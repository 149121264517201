import Layout from '@/views/layout'

const manageRoute = [
  // 系统管理
  {
    path: '/system',
    menu: '系统管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'user',
        menu: '用户管理',
        component: () => import('@/views/system/user'),
        children: [
          {
            path: 'userSchool',
            name: 'userSchool',
            component:  () => import('@/views/system/user/userSchool'),
          },
        ]
      },
      {
        path: 'menuPermission',
        menu: '菜单权限',
        component: () => import('@/views/system/permission')
      },
      {
        path: 'role',
        menu: '角色权限',
        component: () => import('@/views/system/role')
      },
      {
        path: 'school',
        menu: '学校信息管理',
        component: () => import('@/views/system/school')
      },
      {
        path: 'schoolAdmin',
        menu: '学校管理员管理',
        component: () => import('@/views/system/schoolAdmin')
      },
      {
        path: 'filemanage',
        menu: '文件上传管理',
        component: () => import('@/views/system/filemanage')
      }
    ]
  },
  // 帐号授权管理
  {
    path: '/accountManage',
    menu: '帐号授权管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'accountUser/teacherAccount',
        menu: '教师帐号管理',
        props: {
          type: '1'
        },
        component: () => import('@/views/accountManage/accountUser/teacherAccount')
      },
      {
        path: 'accountUser/studentAccount',
        menu: '学生帐号管理',
        props: {
          type: '2'
        },
        component: () => import('@/views/accountManage/accountUser/teacherAccount')
      },
      {
        path: 'accountUser/interceptMobile',
        menu: '过滤手机号配置',
        component: () => import('@/views/productManage/formalProduct/interceptMobile.vue')
      },
      {
        path: 'accountUser/userLoginMore',
        menu: '用户多端登录配置',
        component: () => import('@/views/productManage/formalProduct/userLoginMore.vue')
      },
      {
        path: 'accountUser/userLoginBan',
        menu: '黑名单管理',
        component: () => import('@/views/productManage/formalProduct/userLoginBan.vue')
      },
      {
        path: 'accountSchool/school',
        menu: '学校信息管理',
        component: () => import('@/views/system/school')
      },
      {
        path: 'accountSchool/loginPageSetting',
        menu: '登陆页配置',
        component: () => import('@/views/system/loginPage')
      }
    ]
  },
  // 产品授权管理
  {
    path: '/productManage',
    menu: '产品授权管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'platformFunMange/platformFunMangees',
        menu: '平台功能管理',
        component: () => import('@/views/teacher/platformFunMange/platformMange.vue')
      },
      {
        path: 'platformFunMange/curriculumManage',
        menu: '课程设置管理',
        component: () => import('@/views/teacher/platformFunMange/curriculumManage.vue')
      },
      {
        path: 'practiceCourse/dataPowerConfig',
        menu: '数据权限模板配置',
        component: () => import('@/views/teacher/platformFunMange/dataPowerConfig.vue')
      },
      {
        path: 'practiceCourse/funcTemplateManage',
        menu: '功能模板设置',
        component: () => import('@/views/teacher/platformFunMange/funcTemplateManage.vue')
      },
      {
        path: 'practiceCourse/productCourse',
        menu: '课程使用模板配置',
        component: () => import('@/views/productManage/practiceCourse/productCourse.vue')
      },
      {
        path: 'formalProduct/productSchool',
        menu: '学校产品配置',
        component: () => import('@/views/productManage/formalProduct/productSchool.vue')
      },
      {
        path: 'formalProduct/productSchoolVersion',
        menu: '学校产品版本配置',
        component: () => import('@/views/productManage/formalProduct/productSchoolVersion.vue')
      },
      {
        path: 'formalProduct/schoolTeacher',
        menu: '学校教师配置',
        component: () => import('@/views/productManage/formalProduct/schoolTeacher.vue'),
        children: [
          {
            path: 'schoolTeacherDetail',
            name: 'schoolTeacherDetail',
            component: () => import('@/views/productManage/formalProduct/schoolTeacher/schoolTeacherDetail')
          }
        ]
      },
      {
        path: 'formalProduct/buildingCourses',
        menu: '智能建课授权',
        component: () => import('@/views/productManage/formalProduct/buildingCourses.vue'),
        children: [
          {
            path: 'buildingCoursesDetail',
            name: 'buildingCoursesDetail',
            component: () => import('@/views/productManage/formalProduct/buildingCourses/buildingCoursesDetail')
          }
        ]
      },
      {
        path: 'testPlatform/testPaperTemplateName',
        menu: '试卷模板名称',
        component: () => import('@/views/productManage/testPlatform/testPaperTemplateName.vue')
      },
      {
        path: 'testPlatform/schoolTestPaperManage',
        menu: '试卷模板名称',
        component: () => import('@/views/productManage/testPlatform/schoolTestPaperManage.vue')
      },
      {
        path: 'testPlatform/examSchoolTeacher',
        menu: '学校老师配置',
        component: () => import('@/views/productManage/testPlatform/examSchoolTeacher.vue'),
        children: [
          {
            path: 'examSchoolTeacherDetail',
            name: 'examSchoolTeacherDetail',
            component: () => import('@/views/productManage/testPlatform/examSchoolTeacher/examSchoolTeacherDetail')
          }
        ]
      },
      {
        path: 'simulationPlatform/template',
        menu: '使用量模板配置',
        component: () => import('@/views/productManage/simulationPlatform/template.vue')
      },
      {
        path: 'simulationPlatform/schoolProduct',
        menu: '学校产品配置',
        component: () => import('@/views/productManage/simulationPlatform/schoolProduct.vue')
      },
      {
        path: 'simulationPlatform/schoolTeacher',
        menu: '学校老师配置',
        component: () => import('@/views/productManage/simulationPlatform/schoolTeacher.vue'),
        children: [
          {
            path: 'simulationSchoolTeacherDetail',
            name: 'simulationSchoolTeacherDetail',
            component: () => import('@/views/productManage/simulationPlatform/schoolTeacherDetail')
          }
        ]
      },
      {
        path: 'resourceAuth/schoolPermissionAuth',
        menu: '学校权限配置',
        component: () => import('@/views/productManage/resourcePlatform/schoolConfigure/schoolConfigure.vue')
      },
      {
        path: 'resourceAuth/schoolResourceAuth',
        menu: '学校资源授权',
        component: () => import('@/views/productManage/resourcePlatform/schoolAuthorize/schoolAuthorize.vue')
      },
      {
        path: 'resourceAuth/schoolTeacherAuth',
        menu: '学校老师授权',
        component: () => import('@/views/productManage/resourcePlatform/teacherAuthorize/teacherAuthorize.vue'),
        children: [
        {
          path: 'schoolTeacherAuthDetail',
          name: 'schoolTeacherAuthDetail',
          component: () => import('@/views/productManage/resourcePlatform/teacherAuthorize/schoolTeacherAuthDetail')
        }
      ]
      },
      {
        path: 'resourceAuth/schoolResourceManage',
        menu: '学校资源管理',
        component: () => import('@/views/productManage/resourcePlatform/schoolConfigure/schoolConfigure.vue')
      },
    ]
  },
  // 专业实践管理
  {
    path: '/practice',
    name: '专业实践管理',
    component: Layout,
    children: [
    {
      path: 'schoolmanage/bkksqk',
      menu: '班课开设情况',
      component: () => import('@/views/practice/schoolmanage/bkksqk'),
      children: [
        {
          // 班课开设情况详情
          path: 'classCoursesList',
          component: () => import('@/views/practice/schoolmanage/classCoursesList')
        }
      ]
  },
    {
      path: 'schoolmanage/sytj',
      menu: '使用统计',
      component: () => import('@/views/practice/schoolmanage/sytj')
    },
    {
      path: 'schoolmanage/yhhysctj',
      menu: '用户活跃时长统计',
      component: () => import('@/views/practice/schoolmanage/yhhysctj')
    },
    {
      path: 'schoolmanage/znjk',
      menu: '智能建课',
      component: () => import('@/views/practice/schoolmanage/znjk'),
      children: [
        {
          path: 'previewCourse',
          component: () => import('@/views/practice/schoolmanage/previewCourse'),
          children: [
            // 实践技能
            {
              path: 'practicalSkills',
              component: () => import('@/views/practice/schoolmanage/previewCourse/practicalSkills'),
            },
            // 案例分析
            {
              path: 'caseAnalysis',
              component: () => import('@/views/practice/schoolmanage/previewCourse/caseAnalysis'),
            },
            // 互动实训
            {
              path: 'interactiveTraining',
              component: () => import('@/views/practice/schoolmanage/previewCourse/caseAnalysis'),
            },
            // 综合案例
            {
              path: 'comprehensiveCase',
              component: () => import('@/views/practice/schoolmanage/previewCourse/caseAnalysis'),
            },
            // 试卷
            {
              path: 'testPaper',
              component: () => import('@/views/practice/schoolmanage/previewCourse/caseAnalysis'),
            }
          ],
        },
      ]
    },

    {
      path: 'persimmonConfig/funcPersimmon',
      menu: '功能权限设置',
      component: () => import('@/views/practice/persimmon/funcManage/index')
    },
    {
      path: 'persimmonConfig/lessonSet',
      menu: '课程设置配置',
      component: () => import('@/views/practice/persimmon/lessonSet/index')
    },
    {
      path: 'teaching/classManage',
      name: '班级管理',
      component: () => import('@/views/practice/teaching/classManage/index'),
    },
    {
      path: 'teaching/courseSetting',
      name: '课程设置',
      component: () => import('@/views/practice/teaching/courseSetting/index'),
    },
    {
      path: 'teaching/allCourseSetting',
      name: '全部课程设置',
      component: () => import('@/views/practice/teaching/courseSetting/index2'),
    },
    {
      path: 'teaching/onlineLesson',
      name: '在线课堂',
      component:  () => import('@/views/practice/teaching/onlineLesson/index'),
      children:[
        {
          path: 'classView',
          name: 'classView',
          component: () => import('@/views/practice/teaching/onlineLesson/classView.vue'),
          meta: {
            headList: [
              '课程', '在线课堂', '首页'
            ]
          }
        },
        {
          path: 'classViewHistory',
          name: 'classViewHistory',
          component: () => import('@/views/practice/teaching/onlineLesson/classView.vue'),
          meta: {
            headList: []
          }
        },
        {
          path: 'onlineHistory',
          name: 'onlineHistory',
          component: () => import('@/views/practice/teaching/onlineLesson/onlineHistory')
        },
      ]
    },
    {
      path: 'teaching/resourceManage',
      name: '资源管理',
      component: () => import('@/views/practice/teaching/resourceManage'),
      meta: {
        headList: [
          '教学', '资源管理'
        ]
      }
    },
    {
      path: 'teaching/caseManage',
      name: '案例管理',
      component: () => import('@/views/practice/teaching/caseManage'),
      children:[
        {
          path: 'caseManageDetail',
          name: 'caseManageDetail',
          component: () => import('@/views/practice/teaching/caseManage/caseManageDetail')
        }
      ]
    },
    {
      path: 'teaching/interactionManage',
      name: '互动管理',
      component: () => import('@/views/practice/teaching/interactionManage'),
      children:[
        {
          path: 'interactionProgressManageDetails',
          component: () => import('@/views/practice/teaching/interactionManage/interactionProgressManageDetails'),
        },
        {
          path: 'interactionDoingManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionDoingManage'),
        },
        {
          path: 'interactionGroupManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionGroupManage'),
        },
        {
          path: 'interactionCommentPersonalManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionCommentPersonalManage'),
        },
        {
          path: 'interactionAnswerManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionAnswerManage'),
        },
        {
          path: 'interactionCommentManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionCommentManage'),
        },
        {
          path: 'interactionCommentOtherManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionCommentOtherManage'),
        },
        {
          path: 'interactionHistoryManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionHistoryManage'),
        },
        {
          path: 'interactionHistoryViewManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionHistoryViewManage'),
        },
        {
          path: 'interactionHistoryCommentPersonalManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionHistoryCommentPersonalManage'),
        },
        {
          path: 'interactionRankManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionRankManage'),
        },
        {
          path: 'interactionReportManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionReportManage'),
        },
        {
          path: 'interactionDetailsManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionDetailsManage'),
        },
        {
          path: 'interactionHistoryCommentManage',
          component: () => import('@/views/practice/teaching/interactionManage/interactionHistoryCommentManage'),
        },
        {
          path: 'interactionHistoryCommentOther',
          name: 'interactionHistoryCommentOther',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryCommentOther.vue'),
        },
      ]
    },
    {
      path: 'teaching/taskTeaching',
      name: '任务管理',
      component: () => import('@/views/practice/teaching/task/index'),
      children:[
        {
          path: 'taskRateTeaching',
          name: 'taskRateTeaching',
          component: () => import('@/views/practice/teaching/task/taskRate')
        },
        {
          path: 'taskAnswerReportTeaching',
          name: 'taskAnswerReportTeaching',
          component: () => import('@/views/practice/teaching/task/taskAnswerReport')
        },
      ]
    },
    {
      path: 'teaching/score',
      name: '成绩管理',
      component:  () => import('@/views/practice/teaching/score/index'),
    },
    {
      path: 'teaching/testIndex',
      name: '考试管理',
      component:  () => import('@/views/practice/teaching/test/index'),
      meta: {
        headList: [
          '教学管理', '考试管理', '考试'
        ]
      }
    },
    {
      path: 'teaching/testCreater',
      name: 'testCreaterV2',
      component: () => import('@/views/practice/teaching/test/testCreater.vue'),
      meta: {
        headList: [
          '教学管理', '考试管理', '考试', '新建考试'
        ]
      }
    },
    {
      path: 'teaching/testInvigilate',
      name: 'testInvigilateV2',
      component: () => import('@/views/practice/teaching/test/testInvigilate.vue'),
      meta: {
        headList: [
          '教学管理', '考试管理', '考试', '监考'
        ]
      }
    },
    {
      path: 'teaching/teOnlineTestAnswerReport',
      name: 'teOnlineTestAnswerReportV2',
      component: () => import('@/views/practice/student/test/onlineTestAnswer/onlineTestAnswerReport.vue'),
      meta: {
        headList: [
          '教学管理', '考试管理', '考试', '考试报告'
        ]
      }
    },
    {
      path: 'teaching/testResult',
      name: 'testResultV2',
      component: () => import('@/views/practice/teaching/test/testResult.vue'),
      meta: {
        headList: [
          '教学管理', '考试管理', '考试', '查看结果'
        ]
      }
    },
    {
      path: 'student/teOnlineTestAnswer',
      name: 'teOnlineTestAnswerV2',
      component: () => import('@/views/practice/student/test/onlineTestAnswer/index.vue'),
      meta: {
        headList: [
          '教学', '考试测评', '考试', '预览'
        ]
      }
    },
    // 统计报表
    {
      path: 'teaching/statisticalReport',
      component: () => import('@/views/practice/teaching/test/statisticalReport'),
      children: [
        {
          path: 'assessmentReport',
          name: 'teachingStatisticalReportAssessmentReport',
          component: () => import('@/views/practice/teaching/test/statisticalReport/assessmentReport'),
          meta: {
            headList: [
              '教学', '考试测评', '考试', '查看结果', '统计分析'
            ]
          }
        },
        {
          path: 'achievement',
          name: 'teachingStatisticalReportAchievement',
          component: () => import('@/views/practice/teaching/test/statisticalReport/achievement'),
          meta: {
            headList: [
              '教学', '考试测评', '考试', '查看结果', '统计分析'
            ]
          }
        },
        {
          path: 'learningTime',
          name: 'teachingStatisticalReportLearningTime',
          component: () => import('@/views/practice/teaching/test/statisticalReport/learningTime'),
          meta: {
            headList: [
              '教学', '考试测评', '考试', '查看结果', '统计分析'
            ]
          }
        },
        {
          path: 'righTratio',
          name: 'teachingStatisticalReportRighTratio',
          component: () => import('@/views/practice/teaching/test/statisticalReport/righTratio'),
          meta: {
            headList: [
              '教学', '考试测评', '考试', '查看结果', '统计分析'
            ]
          }
        }
      ]
    },
    {
      path: 'teaching/achievement/achievementDetail',
      component: () => import('@/views/practice/teaching/test/statisticalReport/achievement/achievementDetail'),
      meta: {
        headList: [
          '教学', '考试测评', '考试', '查看结果', '统计分析', '成绩分布', '详情'
        ]
      }
    },
    {
      path: 'schedule/trends',
      name: '学习动态查询',
      component: () => import('@/views/practice/schedule/trends/index'),
      children:[
        {
          name: 'studySkillFinishedReport',
          path: 'studySkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/studySkillFinishedReport'),
          meta: {
            headList: [
              '实践', '学习', '作答完成情况'
            ]
          }
        },
        {
          path: 'taskFinishedReport',
          name: 'taskFinishedReport',
          component: () => import('@/views/practice/schedule/trends/taskFinishedReport.vue'),
          meta: {
            headList: [
              '教学', '教学任务', '完成情况'
            ]
          }
        },
        {
          path: 'practicalSkillFinishedReport',
          name: 'practicalSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/practicalSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '实践技能', '训练完成情况'
            ]
          }
        },
        {
          path: 'hearingSkillFinishedReport',
          name: 'hearingSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/hearingSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '听力训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'oralSkillFinishedReport',
          name: 'oralSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/oralSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '口语训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'writingSkillFinishedReport',
          name: 'writingSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/writingSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '写作训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'readSkillFinishedReport',
          name: 'readSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/readSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '阅读训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'translateSkillFinishedReport',
          name: 'translateSkillFinishedReport',
          component: () => import('@/views/practice/schedule/trends/translateSkillFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '翻译训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'vrFinishedReport',
          name: 'vrFinishedReport',
          component: () => import('@/views/practice/schedule/trends/vrFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '虚拟仿真实验训练', '虚拟仿真实验完成情况'
            ]
          }
        },
        {
          path: 'caseAnalyseFinishedReport',
          name: 'caseAnalyseFinishedReport',
          component: () => import('@/views/practice/schedule/trends/caseAnalyseFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '案例分析', '案例完成情况'
            ]
          }
        },
        {
          path: 'multipleCaseFinished',
          name: 'multipleCaseFinished',
          component: () => import('@/views/practice/schedule/trends/multipleCaseFinished.vue'),
          meta: {
            headList: [
              '实践', '综合案例', '案例完成情况'
            ]
          }
        },
        {
          path: 'wordTrainingFinishedReport',
          name: 'wordTrainingFinishedReport',
          component: () => import('@/views/practice/schedule/trends/wordTrainingFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '单词训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'sentenceTrainingFinishedReport',
          name: 'sentenceTrainingFinishedReport',
          component: () => import('@/views/practice/schedule/trends/sentenceTrainingFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '句子训练', '训练完成情况'
            ]
          }
        },
        {
          path: 'sentencesStrengthenFinishedReport',
          name: 'sentencesStrengthenFinishedReport',
          component: () => import('@/views/practice/schedule/trends/sentencesStrengthenFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '句子强化', '训练完成情况'
            ]
          }
        },
        {
          path: 'paragraphsTrainingFinishedReport',
          name: 'paragraphsTrainingFinishedReport',
          component: () => import('@/views/practice/schedule/trends/paragraphsTrainingFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '段落训练', '训练完成情况'
            ]
          }
        },
        {
          name: 'rolePlayFinishedReport',
          path: 'rolePlayFinishedReport',
          component: () => import('@/views/practice/schedule/trends/rolePlayFinishedReport'),
          meta: {
            headList: [
              '实践', '角色扮演', '完成情况'
            ]
          }
        },
        {
          path: 'paragraphsStrengthenFinishedReport',
          name: 'paragraphsStrengthenFinishedReport',
          component: () => import('@/views/practice/schedule/trends/paragraphsStrengthenFinishedReport.vue'),
          meta: {
            headList: [
              '实践', '段落强化', '训练完成情况'
            ]
          }
        },
        {
          name: 'topicDiscussFinishedReport',
          path: 'topicDiscussFinishedReport',
          component: () => import('@/views/practice/schedule/trends/topicDiscussFinishedReport'),
          meta: {
            headList: [
              '实践', '话题讨论', '训练完成情况'
            ]
          }
        },
        {
          name: 'subjectTestFinishedReport',
          path: 'subjectTestFinishedReport',
          component: () => import('@/views/practice/schedule/trends/subjectTestFinishedReport.vue'),
          meta: {
            headList: [
              '教学', '考试测评', '完成情况'
            ]
          }
        },
        {
          path: 'toeflIelts/toeflIeltsFinished',
          name: 'studentPracticalsToeflIeltsFinished',
          component: () => import('@/views/practice/schedule/trends/toeflIelts/toeflIeltsFinished.vue'),
          meta: {
            headList: [
              '托福、雅思', '完成情况'
            ]
          }
        },
        {
          name: 'speechSkillFinished',
          path: 'speechSkillFinished',
          component: () => import('@/views/practice/schedule/practicalSkills/speechSkillFinished.vue'),
          meta: {
            headList: [
              '实践', '演讲训练', '训练完成情况'
            ]
          }
        },
        {
          name: 'speechSkillReport',
          path: 'speechSkillReport',
          component: () => import('@/views/practice/schedule/trends/speechSkillReport.vue'),
          meta: {
            headList: [
              '实践', '演讲训练', '训练完成情况'
            ]
          }
        },
      ]
    },

    {
      path: 'schedule/practicalSkills',
      name: '实践技能进度',
      component: () => import('@/views/practice/schedule/practicalSkills/index'),
      children:[
        {
          path: 'skillProcess',
          name: 'skillProcess',
          component: () => import('@/views/practice/schedule/practicalSkills/skillProcess'),
          children: [
            {
              path: 'skillHistory',
              name: 'skillHistory',
              component: () => import('@/views/practice/schedule/practicalSkills/skillHistory'),
              children: [
                {
                  path: 'practicalSkillAnswerReport',
                  name: 'practicalSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/practicalSkillAnswerReport')
                },
                {
                  path: 'hearingSkillAnswerReport',
                  name: 'hearingSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/hearingSkillAnswerReport')
                },
                {
                  path: 'oralSkillAnswerReport',
                  name: 'oralSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/oralSkillAnswerReport')
                },
                {
                  path: 'writingSkillAnswerReport',
                  name: 'writingSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/writingSkillAnswerReport')
                },
                {
                  path: 'translateSkillAnswerReport',
                  name: 'translateSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/translateSkillAnswerReport')
                },
                {
                  path: 'vrAnswerReport',
                  name: 'vrAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/vrAnswerReport')
                },
                {
                  path: 'wordTrainingAnswerReport',
                  name: 'wordTrainingAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/wordTrainingAnswerReport')
                },
                {
                  path: 'paragraphsStrengthenAnswerReport',
                  name: 'paragraphsStrengthenAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/paragraphsStrengthenAnswerReport')
                },
                {
                  path: 'billSkillAnswerReport',
                  name: 'billSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/billSkillAnswerReport')
                },
                {
                  path: 'topicDiscussAnswerReport',
                  name: 'topicDiscussAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/topicDiscussAnswerReport')
                },
                {
                  path: 'caseAnalysisReport',
                  name: 'caseAnalysisReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/caseAnalysisReport')
                },
                {
                  path: 'readSkillAnswerReport',
                  name: 'readSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/readSkillAnswerReport')
                },
                {
                  path: 'rolePlayAnswerReport',
                  name: 'rolePlayAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/rolePlayAnswerReport')
                },
                {
                  path: 'speechSkillReport',
                  name: 'speechSkillReportV2',
                  component: () => import('@/views/practice/schedule/trends/speechSkillReport'),
                },
                {
                  path: 'toeflIeltsReport',
                  name: 'toeflIeltsReport',
                  component: () => import('@/views/practice/schedule/trends/toeflIeltsReport.vue'),
                },

                // {
                //   path: 'hearingSkillAnswer',
                //   name: 'hearingSkillAnswer',
                //   component: () => import('@/views/practice/schedule/practicalSkills/answer/hearingSkillAnswer.vue'),
                //   meta: {
                //     headList: [
                //       '实践', '听力训练', '作答'
                //     ]
                //   }
                // },
              ]
            }
          ]
        },
        {
          path: 'stuSkillProcess',
          name: 'stuSkillProcess',
          component: () => import('@/views/practice/schedule/practicalSkills/practical'),
          children: [
            {
              path: 'skillHistory',
              name: 'skillHistory',
              component: () => import('@/views/practice/schedule/practicalSkills/skillHistory'),
              children: [
                {
                  path: 'practicalSkillAnswerReport',
                  name: 'practicalSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/practicalSkillAnswerReport')
                },
                {
                  path: 'hearingSkillAnswerReport',
                  name: 'hearingSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/hearingSkillAnswerReport')
                },
                {
                  path: 'oralSkillAnswerReport',
                  name: 'oralSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/oralSkillAnswerReport')
                },
                {
                  path: 'writingSkillAnswerReport',
                  name: 'writingSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/writingSkillAnswerReport')
                },
                {
                  path: 'translateSkillAnswerReport',
                  name: 'translateSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/translateSkillAnswerReport')
                },
                {
                  path: 'vrAnswerReport',
                  name: 'vrAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/vrAnswerReport')
                },
                {
                  path: 'wordTrainingAnswerReport',
                  name: 'wordTrainingAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/wordTrainingAnswerReport')
                },
                {
                  path: 'paragraphsStrengthenAnswerReport',
                  name: 'paragraphsStrengthenAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/paragraphsStrengthenAnswerReport')
                },
                {
                  path: 'billSkillAnswerReport',
                  name: 'billSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/billSkillAnswerReport')
                },
                {
                  path: 'topicDiscussAnswerReport',
                  name: 'topicDiscussAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/topicDiscussAnswerReport')
                },
                {
                  path: 'caseAnalysisReport',
                  name: 'topicDiscussAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/caseAnalysisReport')
                },
                {
                  path: 'readSkillAnswerReport',
                  name: 'readSkillAnswerReport',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/readSkillAnswerReport')
                },
                {
                  path: 'rolePlayAnswerReport',
                  name: 'rolePlayAnswerReportV2',
                  component: () => import('@/views/practice/schedule/practicalSkills/answerReport/rolePlayAnswerReport')
                },
                {
                  path: 'speechSkillReport',
                  name: 'speechSkillReportV3',
                  component: () => import('@/views/practice/schedule/trends/speechSkillReport'),
                },
                {
                  path: 'toeflIeltsReport',
                  name: 'toeflIeltsReportV2',
                  component: () => import('@/views/practice/schedule/trends/toeflIeltsReport.vue'),
                },
              ]
            }
          ]
        }
      ]
    },
    {
      path: 'practicalSkillsAnsert',
      name: '实践技能进度预览',
      component: () => import('@/views/practice/schedule/practicalSkills/answer/index.vue'),
      children:[
        {
          name: 'hearingSkillAnswer',
          path: 'hearingSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/hearingSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '听力', '作答'
            ]
          }
        },
        {
          name: 'readSkillAnswer',
          path: 'readSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/readSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '阅读', '作答'
            ]
          }
        },
        {
          name: 'practicalSkillAnswer',
          path: 'practicalSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/practicalSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '实践', '作答'
            ]
          }
        },
        {
          name: 'writingSkillAnswer',
          path: 'writingSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/writingSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '写作', '作答'
            ]
          }
        },
        {
          name: 'translateSkillAnswer',
          path: 'translateSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/translateSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '翻译', '作答'
            ]
          }
        },
        {
          name: 'oralSkillAnswer',
          path: 'oralSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/oralSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '口语', '作答'
            ]
          }
        },
        {
          name: 'billSkillAnswer',
          path: 'billSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/billSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '实践-审单', '作答'
            ]
          }
        },
        {
          name: 'subjectTestAnswer',
          path: 'subjectTestAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/subjectTestAnswer.vue'),
          meta: {
            headList: [
              '实践', '试卷', '作答'
            ]
          }
        },
        {
          name: 'speechSkillAnswer',
          path: 'speechSkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/speechSkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '演讲', '作答'
            ]
          }
        },
        {
          name: 'topicDiscussAnswer',
          path: 'topicDiscussAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/topicDiscussAnswer.vue'),
          meta: {
            headList: [
              '实践', '话题讨论', '作答'
            ]
          }
        },
        {
          name: 'studySkillAnswer',
          path: 'studySkillAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/studySkillAnswer.vue'),
          meta: {
            headList: [
              '实践', '学习', '作答'
            ]
          }
        },
        {
          name: 'wordChallengesAnswerBefor',
          path: 'wordChallengesAnswerBefor',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/wordChallengesAnswerBefor.vue'),
          meta: {
            headList: [
              '实践', '单词训练', '作答'
            ]
          }
        },
        {
          name: 'sentenceTrainingAnswer',
          path: 'sentenceTrainingAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/sentenceTrainingAnswer.vue'),
          meta: {
            headList: [
              '实践', '句子训练', '作答'
            ]
          }
        },
        {
          name: 'sentencesStrengthenAnswer',
          path: 'sentencesStrengthenAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/sentencesStrengthenAnswer.vue'),
          meta: {
            headList: [
              '实践', '句子强化', '作答'
            ]
          }
        },
        {
          name: 'paragraphsTrainingAnswer',
          path: 'paragraphsTrainingAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/paragraphsTrainingAnswer.vue'),
          meta: {
            headList: [
              '实践', '段落训练', '作答'
            ]
          }
        },
        {
          name: 'paragraphsStrengthenAnswer',
          path: 'paragraphsStrengthenAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/paragraphsStrengthenAnswer.vue'),
          meta: {
            headList: [
              '实践', '段落强化训练', '作答'
            ]
          }
        },
        {
          name: 'pracCaseAnalyseAnswer',
          path: 'pracCaseAnalyseAnswer',
          component: () => import('@/views/practice/schedule/practicalSkills/answer/caseAnalysisAnswer.vue'),
          meta: {
            headList: [
              '实践', '案例分析', '作答'
            ]
          }
        },
        {
          path: 'caseAnalyseAnswer',
          name: 'caseAnalyseAnswer',
          component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseAnswer.vue'),
          meta: {
            headList: [
              '案例', '案例分析', '作答'
            ]
          }
        },
      ]
    },
    {
      path: 'schedule/caseAnalyse',
      name: '案例分析进度',
      component: () => import('@/views/practice/schedule/caseAnalyse'),
      children:[
        {
          path: 'caseAnalyseProgressDetails',
          name: 'caseAnalyseProgressDetails',
          component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseProgressDetails'),
          children: [
            {
              path: 'caseAnalyseHistory',
              name: 'caseAnalyseHistory',
              component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseHistory'),
              children: [
                {
                  path: 'caseAnalyseReport',
                  name: 'caseAnalyseReport',
                  component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseReport'),
                }
              ]
            }
          ]
        },
        {
          path: 'caseAnalyseReport',
          name: 'caseAnalyseReport',
          component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseReport'),
        },
        {
          path: 'caseAnalyseManage',
          name: 'caseAnalyseManage',
          component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseManage'),
        },
        {
          path: 'caseAnalyseStuProgressDetails',
          name: 'caseAnalyseStuProgressDetails',
          component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseStuProgressDetails'),
          children: [
            {
              path: 'caseAnalyseHistory',
              name: 'caseAnalyseHistory',
              component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseHistory'),
              children: [
                {
                  path: 'caseAnalyseReport',
                  name: 'caseAnalyseReport',
                  component: () => import('@/views/practice/schedule/caseAnalyse/caseAnalyseReport'),
                }
              ]
            }
          ]
        },

      ]
    },
    {
      path: 'schedule/interaction',
      name: '互动实训进度',
      component: () => import('@/views/practice/schedule/interaction'),
      children: [
        {
          path: 'interactionProgressDetails',
          name: 'interactionProgressDetails',
          component: () => import('@/views/practice/schedule/interaction/interactionProgressDetails'),
        },
        {
          path: 'interactionDetails',
          name: 'interactionDetails',
          component: () => import('@/views/practice/schedule/interaction/interactionDetails'),
        },
        {
          path: 'interactionProgressLinkProcess',
          name: 'interactionProgressLinkProcess',
          component: () => import('@/views/practice/schedule/interaction/interactionProgressLinkProcess'),
        },
        {
          path: 'interactionDoing',
          name: 'interactionDoing',
          component: () => import('@/views/practice/schedule/interaction/interactionDoing'),
        },
        {
          path: 'interactionGroup',
          name: 'interactionGroup',
          component: () => import('@/views/practice/schedule/interaction/interactionGroup'),
        },
        {
          path: 'interactionCommentPersonal',
          name: 'interactionCommentPersonal',
          component: () => import('@/views/practice/schedule/interaction/interactionCommentPersonal'),
        },
        {
          path: 'interactionAnswer',
          name: 'interactionAnswer',
          component: () => import('@/views/practice/schedule/interaction/interactionAnswer'),
        },
        {
          path: 'interactionComment',
          name: 'interactionComment',
          component: () => import('@/views/practice/schedule/interaction/interactionComment'),
        },
        {
          path: 'interactionCommentOther',
          name: 'interactionCommentOther',
          component: () => import('@/views/practice/schedule/interaction/interactionCommentOther'),
        },
        {
          path: 'interactionHistory',
          name: 'interactionHistory',
          component: () => import('@/views/practice/schedule/interaction/interactionHistory'),
        },
        {
          path: 'interactionHistoryView',
          name: 'interactionHistoryView',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryView'),
        },
        {
          path: 'interactionHistoryComment',
          name: 'interactionHistoryComment',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryComment'),
        },
        {
          path: 'interactionHistoryCommentOther',
          name: 'interactionHistoryCommentOther',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryCommentOther.vue'),
          meta: {
            headList: [
              '实践', '互动实训', '历史小组评分'
            ]
          }
        },
        {
          path: 'interactionRank',
          name: 'interactionRank',
          component: () => import('@/views/practice/schedule/interaction/interactionRank'),
        },
        {
          path: 'interactionHistoryCommentPersonal',
          name: 'interactionHistoryCommentPersonal',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryCommentPersonal'),
        },
        {
          path: 'interactionReport',
          name: 'interactionReport',
          component: () => import('@/views/practice/schedule/interaction/interactionReport'),
        },
        {
          path: 'interactionCaseTea',
          name: 'interactionCaseTea',
          component: () => import('@/views/practice/schedule/interaction/interactionCase'),
        },
        {
          path: 'interactionHistoryStudent',
          name: 'interactionHistoryStudent',
          component: () => import('@/views/practice/schedule/interaction/interactionHistoryStudent'),
        },
      ]
    },
    {
      path: 'interaction/interactionStart',
      name: '互动实训首页',
      component: () => import('@/views/practice/interaction/interactionStart.vue'),
    },
    {
      path: 'interaction/interactionAnswer',
      name: '互动实训作答',
      component: () => import('@/views/practice/interaction/interactionAnswer.vue'),
      meta: {
        headList: [
          '实践', '互动实训', '作答'
        ]
      }
    },
    {
      path: 'schedule/multipleCase',
      name: '综合案例进度',
      component: () => import('@/views/practice/schedule/multipleCase'),
      children:[
        {
          path: 'multipleProcess',
          name: 'multipleProcess',
          component: () => import('@/views/practice/schedule/multipleCase/multipleProcess')
        },
        {
          path: 'multipleLinkProcess',
          name: 'multipleLinkProcess',
          component: () => import('@/views/practice/schedule/multipleCase/multipleLinkProcess')
        },
        {
          path: 'multipleLinkProcessDetail',
          name: 'multipleLinkProcessDetail',
          component: () => import('@/views/practice/schedule/multipleCase/multipleLinkProcessDetail'),
          meta: {
            headList: [
              "案例环节进度","查看详情"
            ]
          }
        },
        {
          path: 'multipleCaseHistory',
          name: 'multipleCaseHistory',
          component: () => import('@/views/practice/schedule/multipleCase/multipleCaseHistory')
        },
        {
          path: 'multipleLinkList',
          name: 'multipleLinkList',
          component: () => import('@/views/practice/schedule/multipleCase/multipleLinkList')
        },
        {
          path: 'multipleTaskList',
          name: 'multipleTaskList',
          component: () => import('@/views/practice/schedule/multipleCase/multipleTaskList')
        },
        {
          path: 'multipleTaskFinished',
          name: 'multipleTaskFinished',
          component: () => import('@/views/practice/schedule/multipleCase/multipleTaskFinished')
        },
        {
          path: 'mutipleTea',
          name: 'mutipleTea',
          component: () => import('@/views/practice/schedule/multipleCase/mutipleTea')
        }
      ]
    },
    {
      path: 'practicexs/multipleLinkList',
      name: 'practicexsMultipleLinkList',
      component: () => import('@/views/practice/schedule/multipleCase/multipleLinkList'),
      meta: {
        headList: [
          '实践', '综合案例', '环节'
        ]
      }
    },
    {
      path: 'practicexs/multipleTaskList',
      name: 'practicexsMultipleTaskList',
      component: () => import('@/views/practice/schedule/multipleCase/multipleTaskList.vue'),
      meta: {
        headList: [
          '实践', '综合案例', '任务'
        ]
      }
    },
    {
      path: 'practicexs/multipleAnswer',
      name: 'practicexsMultipleAnswer',
      component: () => import('@/views/practice/schedule/multipleCase/multipleAnswer.vue'),
      meta: {
        headList: [
          '实践', '综合案例', '作答'
        ]
      }
    },
    {
      path: 'schedule/task',
      name: '教学任务进度',
      component: () => import('@/views/practice/schedule/taskManage/index'),
      children:[
        {
          path: 'taskRate',
          name: 'taskRate',
          component: () => import('@/views/practice/schedule/taskManage/taskRate')
        },
        {
          path: 'taskAnswerReport',
          name: 'taskAnswerReport',
          component: () => import('@/views/practice/schedule/taskManage/taskAnswerReport')
        },
        {
          path: 'studentProcessDetail',
          name: 'studentProcessDetail',
          component: () => import('@/views/practice/schedule/taskManage/studentProcessDetail')
        }
      ]
    },
    {
      path: 'schedule/exam',
      name: 'exam',
      component: () => import('@/views/practice/schedule/exam/index'),
      children:[
        {
          path: 'subjectTestProcess',
          name: 'subjectTestProcess',
          component: () => import('@/views/practice/schedule/exam/subjectTestProcess')
        },
        {
          name: 'subjectTestHistory',
          path: 'subjectTestHistory',
          component: () => import('@/views/practice/schedule/exam/subjectTest/subjectTestHistory.vue'),
          meta: {
            headList: [
              '教学', '考试测评', '历史情况'
            ]
          }
        },
        {
          name: 'subjectTestAnswerReport',
          path: 'subjectTestAnswerReport',
          component: () => import('@/views/practice/schedule/exam/subjectTest/subjectTestAnswerReport.vue'),
          meta: {
            headList: [
              '教学', '考试测评', '测评报告'
            ]
          }
        },
        {
          name: 'subjectTestFinishedReportExam',
          path: 'subjectTestFinishedReportExam',
          component: () => import('@/views/practice/schedule/exam/subjectTest/subjectTestFinishedReport.vue'),
          meta: {
            headList: [
              '教学', '考试测评', '完成情况'
            ]
          }
        },
        {
          path: 'subjectTestTea',
          name: 'subjectTestTea',
          component: () => import('@/views/practice/schedule/exam/subjectTest.vue')
        },
      ]
    },
    {
      path: 'analysis/learning',
      name: '教学分析',
      component: () => import('@/views/practice/analysis/learning/index'),
      children:[
        {
          path: 'lessonAnalyse',
          name: 'lessonAnalyse',
          component: () => import('@/views/practice/analysis/learning/lessonAnalyse')
        },
        {
          path: 'everyDay',
          name: 'everyDay',
          component: () => import('@/views/practice/analysis/learning/everyDay')
        }
      ]
    },
    ],
  },
  // 运营服务管理
  {
    path: '/serviceManage',
    menu: '运营服务管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'monitor/tomcat',
        menu: '项目服务管理',
        component: () => import('@/views/serviceManage/monitoring/tomcat')
      }
    ]
  },
  // 竞赛评分系统/scoreSetting/manage
  {
    path: '/scoreSystem',
    menu: '竞赛评分系统',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'scoreSetting/manage',
        menu: '管理',
        component: () => import('@/views/scoreSystem/scoreSetting/manage')
      },
      {
        path: 'scoreSetting/scoringIndex',
        menu: '评分指标',
        component: () => import('@/views/scoreSystem/scoreSetting/scoringIndex')
      },
      {
        path: 'scoreSetting/competitionSetup',
        menu: '竞赛设置',
        component: () => import('@/views/scoreSystem/scoreSetting/competitionSetup')
      },
      { path: 'scoreSetting/expertManagement',
        menu: '专家管理',
        component: () => import('@/views/scoreSystem/scoreSetting/expertManagement')
      },
      {
        path: 'scoreSetting/scorePreparation',
        menu: '评分准备',
        component: () => import('@/views/scoreSystem/scoreSetting/scorePreparation')
      },
      {
        path: 'scoreManage/scoreProcess',
        menu: '评分过程',
        component: () => import('@/views/scoreSystem/scoreSetting/scoreProcess')
      },
      {
        path: 'scoreManage/scoreResult',
        menu: '评分成绩',
        component: () => import('@/views/scoreSystem/scoreSetting/scoreResult')
      }
    ]
  },
  // 动态考试测评
  {
    path: '/dynamic/dynamicExamManage',
    menu: '动态考试测评管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'evaluationDym',
        menu: '测评管理',
        component: () => import('@/views/dynamicExam/manage/examManage/examManage.vue'),
        children:[
          {
            path: 'examCourseSet',
            name: 'examCourseSet',
            component: () => import('@/views/dynamicExam/manage/examManage/examCourseSet.vue')
          }
        ]
      },
      {
        path: 'examClassManage',
        menu: '动态考试测评班级管理',
        component: () => import('@/views/dynamicExam/manage/examClassManage/examClassManage.vue')
      },
      {
        path: '/evaluation/examPreview',
        component: () => import('@/views/dynamicExam/manage/examManage/examPreview'),
        meta: {
          headList: [
            '管理', '测评管理', '预览'
          ]
        }
      },
      {
        path: '/evaluation/oralSkillAnswer',
        component: () => import('@/views/practice/schedule/practicalSkills/answer/oralSkillAnswer.vue'),
        meta: {
          headList: [
            '测评', '专项练习', '口语训练', '作答'
          ]
        }
      },
      {
        path: '/evaluation/examErSieTestAnswer',
        component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/examTraining/examErSieTestAnswer.vue'),
        meta: {
          headList: ['作答']
        }
      },
      {
        path: '/evaluation/examTrainingAnswer',
        component: () => import('@/views/student/examTrainingAnswer/index.vue'),
        meta: {
          headList: [
            '作答'
          ]
        }
      },
      // 教师题库
      {
        path: 'teacherQuestionBank',
        component: () => import('@/views/teacher/teacherQuestionBank/index.vue'),
        meta: {
          headList: [
            '管理', '教师题库'
          ]
        }
      },
      // 教师题库-查看详情
      {
        path: '/evaluation/examErSieTestDetails',
        component: () => import('@/views/teacher/examProgressResult/examProgressDetails/examErSieTest/examErSieTestDetails.vue'),
        meta: {
          headList: [
            '详情'
          ]
        }
      },
      // 教师题库-报告
      {
        path: '/evaluation/examErSieTestAnswerReport',
        component: () => import('@/views/student/exam/examHomePage/examErSieTest/examErSieTestAnswerReport.vue'),
        meta: {
          headList: ['报告']
        }
      },
      // 教师题库-更多完成情况
      {
        path: '/evaluation/evalErSieTestFinishedReport',
        component: () => import('@/views/student/exam/examHomePage/examErSieTest/evalErSieTestFinishedReport.vue'),
        meta: {
          headList: ['完成情况']
        }
      },
      // 教师题库-查看历史
      {
        path: '/evaluation/evalErSieTestHistory',
        component: () => import('@/views/student/exam/examHomePage/examErSieTest/evalErSieTestHistory.vue'),
        meta: {
          headList: ['历史情况']
        }
      },
      // 教师题库-学生进度
      {
        path: '/evaluation/examErSieTestProcess',
        component: () => import('@/views/teacher/examProgressResult/examProgressDetails/examErSieTest/examErSieTestProcess.vue'),
        meta: {
          headList: ['学生进度']
        }
      },
      // 教师题库-学生进度-明细
      {
        path: '/evaluation/examErSieTestProcessDetail',
        component: () => import('@/views/teacher/examProgressResult/examProgressDetails/examErSieTest/examErSieTestProcessDetail.vue'),
        meta: {
          headList: ['查看明细']
        }
      },

      {
        path: 'examProgressResult',
        name: 'examProgressResult',
        menu: '动态考试测评进度成绩',
        component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressResult.vue'),
        children:[
          {
            path: 'examProgressReport',
            name: 'examProgressReport',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressReport/index.vue')
          },
          {
            path: 'examProgressDetails',
            name: 'examProgressDetails',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/index.vue'),
            meta: {
              headList: [
                '管理', '进度成绩'
              ]
            }
          },
          // 真题模考
          {
            path: 'examErSieTestDetails',
            name: 'examErSieTestDetails',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examErSieTest/examErSieTestDetails'),
            meta: {
              headList: [
                '管理', '进度成绩', '真题模考详情'
              ]
            }
          },
          {
            name: 'evalErSieTestHistory',
            path: 'evalErSieTestHistory',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/evalErSieTestHistory'),
            meta: {
              headList: [
                '测评', '真题模考', '历史情况'
              ]
            }
          },
          {
            path: 'examErSieTestAnswerReport',
            name: 'examErSieTestAnswerReport',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/examErSieTestAnswerReport'),
            meta: {
              headList: [
                '测评', '真题模考', '报告'
              ]
            }
          },
          {
            name: 'evalErSieTestFinishedReport',
            path: 'evalErSieTestFinishedReport',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/evalErSieTestFinishedReport'),
            meta: {
              headList: [
                '测评', '真题模考', '完成情况'
              ]
            }
          },
          // 专项练习
          {
            path: 'examSpecialPracticeDetails',
            name: 'examSpecialPracticeDetails',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examSpecialPractice/examSpecialPracticeDetails'),
            meta: {
              headList: [
                '管理', '进度成绩', '专项练习详情'
              ]
            }
          },
          {
            path: 'examTrainingHistory',
            name: 'examTrainingHistory',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/examTrainingHistory'),
            meta: {
              headList: [
                '历史情况'
              ]
            }
          },
          {
            path: 'examTrainingReport',
            name: 'examTrainingReport',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/examTrainingReport'),
            meta: {
              headList: [
                '报告'
              ]
            }
          },
          {
            path: 'examTrainingFinishedReport',
            name: 'examTrainingFinishedReport',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/student/examTrainingFinishedReport'),
            meta: {
              headList: [
                '完成情况'
              ]
            }
          },
          {
            path: 'examSpecialPracticeProcess',
            name: 'examSpecialPracticeProcess',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examSpecialPractice/examSpecialPracticeProcess'),
            meta: {
              headList: [
                '管理', '进度成绩', '专项练习', '学生进度'
              ]
            }
          },
          {
            path: 'examSpecialPracticeProcessDetails',
            name: 'examSpecialPracticeProcessDetails',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examSpecialPractice/examSpecialPracticeProcessDetails'),
            meta: {
              headList: [
                '管理', '进度成绩', '专项练习', '学生进度'
              ]
            }
          },
          {
            path: 'examErSieTestProcess',
            name: 'examErSieTestProcess',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examErSieTest/examErSieTestProcess'),
            meta: {
              headList: [
                '管理', '进度成绩', '真题模考', '学生进度'
              ]
            }
          },
          {
            path: 'examErSieTestProcessDetail',
            name: 'examErSieTestProcessDetail',
            component: () => import('@/views/dynamicExam/manage/examProgressResult/examProgressDetails/examErSieTest/examErSieTestProcessDetail'),
            meta: {
              headList: [
                '管理', '进度成绩', '真题模考', '学生进度', '查看明细'
              ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/dynamic/dynamicTestManage',
    menu: '动态考试测评考试管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'testManage',
        menu: '动态考试测评考试管理',
        component: () => import('@/views/dynamicExam/test/index.vue'),
        children:[
          {
            path: 'testCreater',
            name: 'testCreater',
            component: () => import('@/views/dynamicExam/test/testCreater.vue'),
            meta: {
              headList: [
                '考试', '新建考试'
              ]
            }
          },
          {
            path: 'testInvigilate',
            name: 'testInvigilate',
            component: () => import('@/views/dynamicExam/test/testInvigilate.vue'),
            meta: {
              headList: [
                '考试', '监考'
              ]
            }
          },
          {
            path: 'testResult',
            name: 'testResult',
            component: () => import('@/views/dynamicExam/test/testResult.vue'),
            meta: {
              headList: [
                '考试', '查看结果'
              ]
            }
          },
          {
            path: 'teOnlineTestAnswerReport',
            name: 'teOnlineTestAnswerReport',
            component: () => import('@/views/dynamicExam/test/student/onlineTestAnswerReport.vue'),
            meta: {
              headList: [
                '考试', '在线考试', '考试报告'
              ]
            }
          },
          {
            path: 'examination/teOnlineTestAnswerReport',
            name: 'examinationteOnlineTestAnswerReport',
            component: () => import('@/views/dynamicExam/test/onlineTestAnswer/onlineTestAnswerReport.vue'),
            meta: {
              headList: [
                '考试', '在线考试', '考试报告'
              ]
            }
          },
          {
            path: '/examination/statisticalReport',
            component: () => import('@/views/teacher/statisticalReport/index.vue'),
            children: [
              {
                path: '/examination/statisticalReport/assessmentReport',
                name: 'examinationStatisticalReportAssessmentReport',
                component: () => import('@/views/teacher/statisticalReport/assessmentReport/index.vue'),
                meta: {
                  headList: [
                    '考试', '查看结果', '统计分析'
                  ]
                }
              },
              {
                path: '/examination/statisticalReport/achievement',
                name: 'examinationStatisticalReportAchievement',
                component: () => import('@/views/teacher/statisticalReport/achievement/index.vue'),
                meta: {
                  headList: [
                    '考试', '查看结果', '统计分析'
                  ]
                }
              },
              {
                path: '/examination/statisticalReport/learningTime',
                name: 'examinationStatisticalReportLearningTime',
                component: () => import('@/views/teacher/statisticalReport/learningTime/index.vue'),
                meta: {
                  headList: [
                    '考试', '查看结果', '统计分析'
                  ]
                }
              },
              {
                path: '/examination/statisticalReport/righTratio',
                name: 'examinationStatisticalReportRighTratio',
                component: () => import('@/views/teacher/statisticalReport/righTratio/index.vue'),
                meta: {
                  headList: [
                    '考试', '查看结果', '统计分析'
                  ]
                }
              }
            ]
          },
          {
            path: '/examination/achievementDetail',
            component: () => import('@/views/teacher/statisticalReport/achievement/achievementDetail/index.vue'),
            meta: {
              headList: [
                '考试', '查看结果', '统计分析', '成绩分布', '详情'
              ]
            }
          },
        ]
      },
      {
        path: 'teOnlineTestAnswerOnline',
        name: 'teOnlineTestAnswerOnline',
        component: () => import('@/views/practice/student/test/onlineTestAnswer/index.vue'),
        meta: {
          headList: [
            '考试', '在线考试', '预览'
          ]
        }
      }
    ]
  },
  {
    path: '/dynamic/dynamicSchoolManage',
    menu: '学校管理',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'xxks',
        menu: '线上考试',
        component: () => import('@/views/dynamicExam/test/index.vue'),
      },
      {
        path: 'yckstgl',
        menu: '预测考试通过率',
        component: () => import('@/views/dynamicExam/schoolManage/yckstgl'),
      },
      {
        path: 'bjzxlxtj',
        menu: '班级专项练习统计',
        component: () => import('@/views/dynamicExam/schoolManage/bjzxlxtj'),
      },
      {
        path: 'bjztmktj',
        menu: '班级真题模考统计',
        component: () => import('@/views/dynamicExam/schoolManage/bjztmktj'),
      },
      {
        path: 'xszxlxtj',
        menu: '学生专项练习统计',
        component: () => import('@/views/dynamicExam/schoolManage/xszxlxtj'),
      },
      {
        path: 'xsztmktj',
        menu: '学生真题模考统计',
        component: () => import('@/views/dynamicExam/schoolManage/xsztmktj'),
      },
      {
        path: 'tjbb',
        menu: '统计报表',
        redirect: '/dynamic/dynamicSchoolManage/tjbb/platform',
        component: () => import('@/views/dynamicExam/schoolManage/statisticalReport'),
        children: [
          {
            path: 'platform',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/platform'),
          },
          {
            path: 'teacherQuestionBank',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/teacherQuestionBank'),
          },
          {
            path: 'specialExercisesStatistics',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics'),
            children: [
              {
                path: 'achievement',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/achievement'),
                meta: {
                  headList: [
                    '专项练习统计'
                  ]
                }
              },
              {
                path: 'learningTime',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/learningTime'),
                meta: {
                  headList: [
                    '专项练习统计'
                  ]
                }
              },
              {
                path: 'righTratio',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/righTratio'),
                meta: {
                  headList: [
                    '专项练习统计'
                  ]
                }
              }
            ]
          },
          {
            path: 'achievementDetail',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/achievement/achievementDetail'),
            meta: {
              headList: [
                '成绩分布'
              ]
            },
          },
          {
            path: 'trueTopicDryRunStatistics',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/trueTopicDryRunStatistics'),
            meta: {
              headList: [
                '真题模考'
              ]
            },
          },
          {
            path: 'trueTopicDryRunStatistics/details',
            component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/trueTopicDryRunStatistics/details'),
            children: [
              {
                path: 'achievement',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/achievement'),
                meta: {
                  headList: [
                    '真题模考', '统计'
                  ]
                }
              },
              {
                path: 'learningTime',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/trueTopicDryRunStatistics/details/learningTime'),
                meta: {
                  headList: [
                    '真题模考', '统计'
                  ]
                }
              },
              {
                path: 'righTratio',
                component: () => import('@/views/dynamicExam/schoolManage/statisticalReport/specialExercisesStatistics/righTratio'),
                meta: {
                  headList: [
                    '真题模考', '统计'
                  ]
                }
              }
            ]
          },
        ]
      },
    ]
  },

  // 统计报表
  // {
  //   path: '/examination/statisticalReport',
  //   component: () => import('@/views/teacher/statisticalReport/index.vue'),
  //   children: [
  //     {
  //       path: '/examination/statisticalReport/assessmentReport',
  //       name: 'examinationStatisticalReportAssessmentReport',
  //       component: () => import('@/views/teacher/statisticalReport/assessmentReport/index.vue'),
  //       meta: {
  //         headList: [
  //           '考试', '查看结果', '统计分析'
  //         ]
  //       }
  //     },
  //     {
  //       path: '/examination/statisticalReport/achievement',
  //       name: 'examinationStatisticalReportAchievement',
  //       component: () => import('@/views/teacher/statisticalReport/achievement/index.vue'),
  //       meta: {
  //         headList: [
  //           '考试', '查看结果', '统计分析'
  //         ]
  //       }
  //     },
  //     {
  //       path: '/examination/statisticalReport/learningTime',
  //       name: 'examinationStatisticalReportLearningTime',
  //       component: () => import('@/views/teacher/statisticalReport/learningTime/index.vue'),
  //       meta: {
  //         headList: [
  //           '考试', '查看结果', '统计分析'
  //         ]
  //       }
  //     },
  //     {
  //       path: '/examination/statisticalReport/righTratio',
  //       name: 'examinationStatisticalReportRighTratio',
  //       component: () => import('@/views/teacher/statisticalReport/righTratio/index.vue'),
  //       meta: {
  //         headList: [
  //           '考试', '查看结果', '统计分析'
  //         ]
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/examination/achievementDetail',
  //   component: () => import('@/views/teacher/statisticalReport/achievement/achievementDetail/index.vue'),
  //   meta: {
  //     headList: [
  //       '考试', '查看结果', '统计分析', '成绩分布', '详情'
  //     ]
  //   }
  // },

  // 虚拟仿真实验
  {
    path: '/simulation',
    menu: '虚拟仿真实验进度',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'main',
        menu: '进度',
        component: () => import('@/views/simulation/index.vue'),
        meta: {
          headList: [
            '虚拟仿真实验'
          ]
        }
      },
      {
        path: 'progressDetails',
        name: 'progressDetails',
        component: () => import('@/views/simulation/testProgress/progressDetails'),
        meta: {
          headList: []
        }
      },
      {
        path: 'virtualSimulationHistory',
        name: 'virtualSimulationHistory',
        component: () => import('@/views/simulation/testProgress/student/virtualSimulation/virtualSimulationHistory'),
        meta: {
          headList: []
        }
      },
      {
        path: 'virtualSimulationReport',
        name: 'virtualSimulationReport',
        component: () => import('@/views/simulation/testProgress/student/virtualSimulation/virtualSimulationReport'),
        meta: {
          headList: []
        }
      },
      {
        path: 'testPreview',
        name: 'testPreview',
        component: () => import('@/views/simulation/testProgress/testPreview'),
        meta: {
          headList: []
        }
      },
      {
        path: 'caseProgressDetails',
        name: 'caseProgressDetails',
        component: () => import('@/views/simulation/caseProgress/caseProgressDetails'),
        meta: {
          headList: []
        }
      },
      {
        path: 'caseProcessHistory',
        name: 'caseProcessHistory',
        component: () => import('@/views/simulation/caseProgress/student/caseProcessHistory'),
        meta: {
          headList: []
        }
      },
      {
        path: 'vrCaseProcessLink',
        name: 'vrCaseProcessLink',
        component: () => import('@/views/simulation/caseProgress/student/caseProcessLink'),
        meta: {
          headList: []
        }
      },
      {
        path: 'caseProcessReport',
        name: 'caseProcessReport',
        component: () => import('@/views/simulation/caseProgress/student/caseProcessReport'),
        meta: {
          headList: []
        }
      },
      {
        path: 'casePreview',
        name: 'casePreview',
        component: () => import('@/views/simulation/caseProgress/casePreview'),
        meta: {
          headList: []
        }
      },
    ]
  },
  {
    path: '/simulation/simulationManage',
    menu: '虚拟仿真实验进度',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'vrClassManage',
        name: 'vrClassManage',
        component: () => import('@/views/simulation/manage/classManage'),
        meta: {
          headList: [
            '管理', '班级管理'
          ]
        }
      },
      {
        path: 'vrTestSet',
        name: 'vrTestSet',
        component: () => import('@/views/simulation/manage/testSet'),
        meta: {
          headList: [
            '管理', '实验设置'
          ]
        }
      },
      {
        path: 'vrResultManage',
        name: 'vrResultManage',
        component: () => import('@/views/simulation/manage/resultManage'),
        meta: {
          headList: [
            '管理', '成绩管理'
          ]
        }
      },
      {
        path: 'vrTestAnalyse',
        name: 'vrTestAnalyse',
        component: () => import('@/views/simulation/manage/testAnalyse'),
        meta: {
          headList: [
            '管理', '实验分析'
          ]
        }
      },
      {
        path: 'resultPublishDetails',
        name: 'resultPublishDetails',
        component: () => import('@/views/simulation/manage/testAnalyse/components/resultPublishDetails'),
        meta: {
          headList: [
            '管理', '实验分析', '实验分布详情'
          ]
        }
      }
    ]
  },
  // 工具类
  {
    path: '/toolServices',
    menu: '工具',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'certificate/typeManage',
        menu: '证书类型管理',
        component: () => import('@/views/toolServices/certificate/typeManage')
      },
      {
        path: 'certificate/templateManage',
        menu: '证书模板管理',
        component: () => import('@/views/toolServices/certificate/templateManage')
      },
      {
        path: 'certificateComp/compSetManage',
        menu: '竞赛设置管理',
        component: () => import('@/views/toolServices/certificate/compSetManage')
      },
      {
        path: 'certificateComp/stuCompManage',
        menu: '学生竞赛证书管理',
        component: () => import('@/views/toolServices/certificate/stuCompManage')
      },
      {
        path: 'certificateComp/teaCompManage',
        menu: '老师竞赛证书管理',
        component: () => import('@/views/toolServices/certificate/teaCompManage')
      },
      {
        path: 'certificateComp/schCompManage',
        menu: '学校竞赛证书管理',
        component: () => import('@/views/toolServices/certificate/schCompManage')
      },
      {
        path: 'businessNegoComp/teamNegoManage',
        menu: '商务谈判团队证书管理',
        component: () => import('@/views/toolServices/nego/teamNegoManage')
      },
      {
        path: 'businessNegoComp/schNegoManage',
        menu: '商务谈判学校证书管理',
        component: () => import('@/views/toolServices/nego/schNegoManage')
      },
      {
        path: 'chargeManage/chargeTool',
        menu: '收费事项管理',
        component: () => import('@/views/toolServices/charge/chargeTool')
      },
      {
        path: 'chargeManage/stuChargeManage',
        menu: '学生收费信息管理',
        component: () => import('@/views/toolServices/charge/stuChargeManage')
      },
      {
        path: 'chargeManage/teaChargeManage',
        menu: '老师收费信息管理',
        component: () => import('@/views/toolServices/charge/teaChargeManage')
      },
      // {
      //   path: 'certificateComp/teaCompManage',
      //   menu: '老师竞赛证书管理',
      //   component: () => import('@/views/toolServices/certificate/teaCompManage')
      // },
      // {
      //   path: 'certificateComp/schoolCompManage',
      //   menu: '学校竞赛证书管理',
      //   component: () => import('@/views/toolServices/certificate/schoolCompManage')
      // },
      // {
      //   path: 'certificateIntern/setBeating',
      //   menu: '证书套打',
      //   component: () => import('@/views/toolServices/certificate/setBeating')
      // }
      {
        path: 'certificateIntern/stuPracManage',
        menu: '学生实习证书管理',
        component: () => import('@/views/toolServices/certificate/stuPracManage')
      },
      {
        path: 'certificateIntern/teaPracManage',
        menu: '老师实习证书管理',
        component: () => import('@/views/toolServices/certificate/teaPracManage')
      }
    ]
  },
  // 外语能力竞赛平台
  {
    path: '/ability',
    menu: '外语能力竞赛平台',
    component: Layout,
    hasChildren: true,
    children: [
      {
        path: 'bmxq',
        menu: '报名详情',
        component: () => import('@/views/ability/bmxq'),
        children: [
          {
            // 竞赛分组
            path: 'grouping',
            component: () => import('@/views/ability/grouping')
          }
        ]
      },
      {
        path: 'csmx',
        menu: '初赛明细',
        component: () => import('@/views/ability/csmx'),
        children: [
          {
            // 竞赛列表
            path: 'abilityList',
            component: () => import('@/views/ability/abilityList')
          },
          {
            // 竞赛监考
            path: 'testInvigilate',
            component: () => import('@/views/ability/testInvigilate')
          },
          {
            // 竞赛结果
            path: 'testResult',
            component: () => import('@/views/ability/testResult')
          },
          // 统计报表
          {
            path: 'statisticalReport',
            component: () => import('@/views/ability/statisticalReport'),
            children: [
              {
                path: 'assessmentReport',
                component: () => import('@/views/ability/statisticalReport/assessmentReport'),
              },
              {
                path: 'achievement',
                component: () => import('@/views/ability/statisticalReport/achievement'),
              },
              {
                path: 'learningTime',
                component: () => import('@/views/ability/statisticalReport/learningTime'),
              }
            ]
          },
          {
            path: 'achievementDetail',
            component: () => import('@/views/ability/statisticalReport/achievement/achievementDetail')
          },
          {
            path: 'teOnlineTestAnswerReport',
            component: () => import('@/views/ability/onlineTestAnswer/onlineTestAnswerReport.vue'),
          },
        ]
      }
    ]
  }
]

export default manageRoute
